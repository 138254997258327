@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat'),
  url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
}